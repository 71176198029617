f<template>
    <div class="body">
	<ul style="padding: .5ex 0">
	    <li> <b> PhD student, Computer Science </b> </li>
	    <li> <a href="https://hotsoft.carleton.ca" target="_blank" class="external link">HotSoft Lab</a>, Carleton University</li>
	    <li> Ottawa, Canada</li>
	    <li> <a href="mailto:eric.spero@carleton.ca" class="email link">eric.spero@carleton.ca</a></li>
	</ul>
	
	<p> My current research is aimed at improving user security through interface design. Users are frequently asked to make decisions with security implications because infrastructural defences are imperfect. People make decisions using their internal models of the world (mental models), and these models are built through experience. We contend that user interfaces are too information poor when it comes to security matters, and this inhibits mental model development. We will design, implement, and empirically evaluate new user interfaces that communicate security information that we hope will lead to good mental models, in a frugal manner.</p>

	<p> My background is in cognitive science (B.Cog.Sc.) and human-computer interaction (M.A.). For my master's thesis I designed and implemented a non-linear electronic textbook format, a live demo of which can be seen <a href="https://mvp.soft.carleton.ca/~espero/TextDemo/book.html" target="_blank" class="external link">here</a>.</p>
	
	<p> Thank you for visiting. This website is a work in progress, so please excuse any gaps or errors. </p>
    </div>
</template>
<style scoped>
 .about {
     text-align: left;
 }

</style>
