<template>
    <div id="topbanner">
	<h2 style="margin:0; margin-top:20px"> Eric Spero </h2>
	<div style="margin:12px 0"> UI research and design guided by cognitive science theory </div>
	<div id="nav">
	    <router-link to="/">About</router-link> |
	    <router-link to="/projects">Projects</router-link> | 
	    <router-link to="/publications">Publications</router-link> | 
	    <router-link to="/cv" target="_blank" class="external link">CV</router-link>
	</div>
	<hr style="border-top:100px; margin: 0; margin-top: 20px; color:grey;">
    </div>
    
    
    <!-- <div id="filler"/> -->
    <router-view/>
</template>

<style>

 html {
     overflow-y: scroll;
 }

 #app {
     font-family: Avenir, Helvetica, Arial, sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     text-align: center;
     width: 38em;
     margin-left: auto;
     margin-right: auto;
     color: #2c3e50;
 }

 @media only screen and (max-width: 600px) {
     #app {
	 margin: 1ex;
	 width: calc(100% - 1ex);
     }
 }

 .link:after {
     content:"";
     width:11px;
     height:11px;
     margin-left:2px;
     display:inline-block;
 }

 .external:after{
     background: url("./assets/external-link.svg");
 }

 .doi:after{
     background: url("./assets/external-link.svg");
 }

 .email:after{
     background: url("./assets/email.svg");
 }

 #nav {
     margin: 12px 0;
     text-align:left;
 }

 #nav a {
     font-weight: bold;
     color: #2c3e50;
 }

 #nav a.router-link-exact-active {
     color: #296AB2;
     
 }

 a:link {
     color: darkblue;
 }

 a:visited{
     color: #7129B2;
 }

 h4 {
     margin: 0px;
 }

 ul {
     list-style-type: none;
     padding: 0;
 }
 
 li {
     padding: 0px 0;
     margin: 2px 0;
 }

 h3, h4{
     text-align: left;
     margin-top:2ex;
     margin-bottom:1.5ex;
 }


 #topbanner {
     text-align:left;
     padding: 2ex 0;
 }

 #filler {
     height:1ex;
 }

 .body {
     text-align: left;
     padding:0;
     padding-bottom: 2ex;
 }

 .pubproj{
     text-align: left;
     padding:0;
     margin: 3.5ex 0;
 }

</style>
