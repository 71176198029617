import { createRouter, createWebHistory } from 'vue-router'
//import Home from '../views/Home.vue'
import About from '../views/About.vue'

const routes = [
    {
	path: '/',
	name: 'Home',
	component: About
    },
    {
	path: '/projects',
	name: 'Projects',
	component: () => import('../views/Projects.vue')
    },
    {
	path: '/publications',
	name: 'Publications',
	title: 'Eric Spero - Publications',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () => import(/* webpackChunkName: "about" */ '../views/Publications.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
